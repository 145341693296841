/* This stylesheet generated by Transfonter (http://transfonter.org) on June 28, 2016 7:29 AM */
@font-face {
  font-family: "Arian AMU";
  src: url("../files/font/ArianAMU-Bold.eot");
  src: url("../files/font/ArianAMU-Bold.eot?#iefix") format("embedded-opentype"), url("../files/font/ArianAMU-Bold.woff") format("woff"), url("../files/font/ArianAMU-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Arian AMU";
  src: url("../files/font/ArianAMU.eot");
  src: url("../files/font/ArianAMU.eot?#iefix") format("embedded-opentype"), url("../files/font/ArianAMU.woff") format("woff"), url("../files/font/ArianAMU.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Arian AMU";
  src: url("../files/font/ArianAMU-BoldItalic.eot");
  src: url("../files/font/ArianAMU-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../files/font/ArianAMU-BoldItalic.woff") format("woff"), url("../files/font/ArianAMU-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "Arian AMU";
  src: url("../files/font/ArianAMU-Italic.eot");
  src: url("../files/font/ArianAMU-Italic.eot?#iefix") format("embedded-opentype"), url("../files/font/ArianAMU-Italic.woff") format("woff"), url("../files/font/ArianAMU-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
html[lang="hy-AM"] body, html[lang="hy-AM"] .coder-dropdown {
  font-family: "Arian AMU" !important;
}

.deal-status, .bid-participant-status {
  padding: 4px 10px !important;
  border-radius: 4px;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1;
}

.table tr.not_readed {
  font-weight: bold !important;
}
